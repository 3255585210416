/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'g9CbmFTqCAojQorHy5Nddh.png': { uri: '/images/g9CbmFTqCAojQorHy5Nddh.png' },
'bYvQ5LefUpAJvYcZVfjUZV.png': { uri: '/images/bYvQ5LefUpAJvYcZVfjUZV.png' },
'wGUDniEoiC93FWGi7v6vqL.jpg': { uri: '/images/wGUDniEoiC93FWGi7v6vqL.jpg' },
'iDFQfpddLhmE3Tt1JoeRQb.jpg': { uri: '/images/iDFQfpddLhmE3Tt1JoeRQb.jpg' },
'jYbGbParTKm8gaR9kG8vy8.png': { uri: '/images/jYbGbParTKm8gaR9kG8vy8.png' },
'sFRj17sCjNbmgHVbFqBfko.png': { uri: '/images/sFRj17sCjNbmgHVbFqBfko.png' },
'b8BnyZo5VSTRQaomZyDKy4.png': { uri: '/images/b8BnyZo5VSTRQaomZyDKy4.png' },
'9JPCJBXnmck3QQzajmHCuJ.jpg': { uri: '/images/9JPCJBXnmck3QQzajmHCuJ.jpg' }
}

export default imageStaticSourcesByFileName
